<template>
<b-container>
  <div class="my-5 pt-5">
    <h4>
      เข้าสู่ระบบ
    </h4>
    <divider />
    <b-container class="mt-5">
      <b-form  v-on:submit.prevent="onLogin">
        <b-form-group class="col-lg-6 col-md-8 col-sm-12 m-auto"> 
          <label> E-mail</label>
          <b-form-input type="email" v-model="email" required/>
        </b-form-group>
        <b-form-group class="col-lg-6 col-md-8 col-sm-12 m-auto mt-2"> 
          <label> รหัสผ่าน</label>
          <b-form-input type="password" v-model="password" required/>
          <router-link to="/forgot" class="forgot mt-2">ลืมรหัสผ่าน</router-link>
        </b-form-group>
        <b-form-group class="col-lg-4 col-md-6 col-sm-10 pt-3 m-auto"> 
          <b-button type="submit" size="lg" class="btn-primary btn btn-block mt-4 mr-3">
            เข้าสู่ระบบ
          </b-button>
        </b-form-group>
        <b-form-group class="col-lg-4 col-md-6 col-sm-10 m-auto"> 
          <p class="gray text-center m-2">หากท่านไม่เคยสมัครใช้งาน กดลงทะเบียนที่นี่</p>
          <b-button type="submit" size="lg" variant="primary" class="btn-primary-light btn-block" to="/register">
            ลงทะเบียน
          </b-button>
        </b-form-group>
      </b-form>
    </b-container>
  </div>
</b-container>
</template>

<script>
import Divider from "@/components/Divider";
import { authLoginUser } from "@/services/auth.service"
export default {
  components: {
    Divider
  },
  data() {
    return {
      email: "",
      password: ""
    }
  },
  methods: {
    async onLogin() {
      const payload = {
        username: this.email,
        password: this.password
      }
      const response = await authLoginUser(payload)
      console.log({ response })
      
      if(response.error){
        this.$toast.error(response.message)
        return;
      }else {
        window.location.href = "/";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  a.forgot {
    color: $primary-hard-color;
    justify-content: flex-end;
    display: flex;
    width: 30%;
    margin-left: auto;
  }
  label {
    font-size: 20px;
    color: $primary-hard-color;
    margin: 0;
  }
  button {
    padding: 4px;
  }
</style>